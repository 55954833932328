import { useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';

import { breakpoints } from '../../theme';
import { ScreenTypes } from '../../types.d';

const getBreakPointConfig = (width: number): ScreenTypes | null => {
    if (width < breakpoints.xs) {
        return ScreenTypes.xxs;
    }
    if (width < breakpoints.sm) {
        return ScreenTypes.xs;
    }
    if (width >= breakpoints.sm && width < breakpoints.md) {
        return ScreenTypes.sm;
    }
    if (width >= breakpoints.md && width < breakpoints.lg) {
        return ScreenTypes.md;
    }
    if (width >= breakpoints.lg && width < breakpoints.xl) {
        return ScreenTypes.lg;
    }
    return ScreenTypes.xl;
};

/**
 * Кастомный хук для определения текущего breakpoint на странице.
 *
 * @returns {ScreenTypes | null} - Возвращает строку breakpoint или null, если не удалось определить
 *
 * @example
 * import {useBreakpoints, ScreenTypes} from '@utils/hooks/useBreakpoints';
 *
 * function MyComponent() {
 *   const breakpoint = useBreakpoints();
 *
 *   return (
 *     <div>
 *       {breakpoint === ScreenTypes.xs && <p>Extra small screen</p>}
 *       {breakpoint === ScreenTypes.sm && <p>Small screen</p>}
 *       {breakpoint === ScreenTypes.md && <p>Medium screen</p>}
 *       {breakpoint === ScreenTypes.lg && <p>Large screen</p>}
 *       {breakpoint === ScreenTypes.xl && <p>Extra large screen</p>}
 *     </div>
 *   );
 * }
 */
const useBreakpoints = (): ScreenTypes | null => {
    const [breakpoint, setBreakpoint] = useState<ScreenTypes>(null);

    const handleResize = useCallback(
        () =>
            throttle(() => {
                const currentBreakpoint = getBreakPointConfig(window.innerWidth);
                if (currentBreakpoint && currentBreakpoint !== breakpoint) {
                    setBreakpoint(currentBreakpoint);
                }
            }, 150),
        [breakpoint],
    );

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return breakpoint;
};

export { useBreakpoints, ScreenTypes };
